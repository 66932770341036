import _get from 'lodash/get'
import _isFinite from 'lodash/isFinite'
import { QUESTION_TYPE, ANSWER_TYPE, NOTIFICATION } from 'constants/additionalQuestion'
import { BOOKING_METHOD } from 'constants/reservation'
import Reservations from 'api/Reservations'
import _uniqBy from 'lodash/uniqBy'
import Rooms from 'api/Rooms'
import { generateBrandLabelFilters } from 'utilities/additionalQuestions'
import { findDiscountInRoom } from 'utilities/discount'
import { isOta, JapanPrefectureMap } from 'constants/tripla'
import { formatFieldNameBaseOnMcp } from 'src/composables/useMcp'
import { filterNotificationsByCheckinDate } from 'utilities/notifications'

const state = {
  isUpdateMode: false,
  isApplyingAfterCoupon: false,
  bookingDetails: {
    rooms: [],
    guest: {} // Should be empty object
  },
  isBookingFromPayment: false,
  reservationDetails: {},
  additionalQuestions: [],
  facilityNotifications: [],
  brandNotifications: [],
  guests: [],
  points: {
    selectedSpendingPoint: 0,
    adjustPoint: 0
  },
  updateSetting: {
    is_active: false,
    updatable: false,
    reason: null
  },
  currentStep: 0,
  brandAdditionalQuestionsSuccess: false,
  uploadingAttachments: [], // [promise]
  hotelInfo: {},
  dataLayerPaymentEventPayloadFromBw: null,
  gtmSessionsFromBw: null,
  trackingInfoFromBw: {},
  lineLiffToken: ''
}

const getters = {
  isBookingFromPayment: (state) => state.isBookingFromPayment,
  isLotteryBooking: (state) => !!state.bookingDetails?.lottery_result?.entry_number,
  isLotteryReservation: (state) => !!state.reservationDetails?.lottery_result?.entry_number,
  getBookingDetails: (state) => {
    return {
      ...state.bookingDetails,
      rooms: (state.bookingDetails?.rooms || []).map((room) => {
        return {
          ...room,
          rounding_discount: findDiscountInRoom(room, 'rounding')
        }
      })
    }
  },
  getGuestAddressInfo: (state, getters, rootState, rootGetters) => {
    const { guest } = getters.getBookingDetails

    return rootGetters['setting/getInit'].customer_address_required
      ? {
          postal_code: guest.postal_code,
          prefecture_or_state:
            JapanPrefectureMap[guest.prefecture_or_state] || guest.prefecture_or_state,
          street_address: guest.street_address,
          building_name_and_number: guest.building_name_and_number
        }
      : {}
  },
  getReservationDetails: (state) => state.reservationDetails,
  getGuest: (state) => state.bookingDetails.guest,
  getFacilityNotifications: (state, getters, rootState, rootGetters) => {
    if (getters.isLotteryBooking || rootGetters['lottery/getIsLotteryEntry']) return []

    const { checkin } = rootGetters['search/getSearchForm']
    return filterNotificationsByCheckinDate(state.facilityNotifications, checkin)
  },
  getAdditionalQuestions: (state) => state.additionalQuestions,
  hasAdditionalQuestions: (state) => state.additionalQuestions?.length > 0,
  getGuestsList: (state) => state.guests,
  getPoints: (state) => state.points,
  getCurrentAppliedPoint: (state) =>
    state.points.selectedSpendingPoint < 0
      ? state.points.adjustPoint
      : state.points.selectedSpendingPoint,
  getUpdateSetting: (state) => state.updateSetting,
  getHotelURL: (state) => state.reservationDetails?.hotel?.url,
  isBwToOtaReservation: (state) =>
    state.reservationDetails?.booking_method === BOOKING_METHOD.BOOKING_WIDGET_TO_TRIPLA_OTA,
  isUpdateMode: (state) => state.isUpdateMode,
  isApplyingAfterCoupon: (state) => state.isApplyingAfterCoupon,
  isBookingForSomeoneElse: (state) => {
    const { guest, rooms } = state.bookingDetails
    const isGuestNamesIdentical = (g1, g2) =>
      g1.first_name === g2.first_name && g1.last_name === g2.last_name
    return guest?.first_name && rooms[0]?.guest && !isGuestNamesIdentical(guest, rooms[0].guest)
  },
  getLimitedPlans: (state, getters, rootState) => {
    if (rootState.setting.init.hotel_site_controller === 'tl_lincon') {
      const plans = (state.reservationDetails?.rooms ?? []).map((room) => {
        return { name: room.room_plan_name, hotel_plan_code: room.hotel_plan_code }
      })
      return _uniqBy(plans, 'hotel_plan_code')
    }
    return []
  },
  brandNotifications: (state, getters) => getters.getBrandNotifications(),
  getBrandNotifications: (state, getters, rootState, rootGetters) => (
    checkin = rootGetters['search/getSearchForm'].checkin
  ) => {
    if (rootGetters['lottery/getIsLotteryEntry']) {
      const lotteryEvent = rootGetters['lottery/getLotteryEventSetting']
      return lotteryEvent?.notification
        ? [{ id: lotteryEvent.id, question: lotteryEvent.notification }]
        : []
    }

    return filterNotificationsByCheckinDate(state.brandNotifications, checkin)
  },
  getCurrentStep: (state) => state.currentStep,
  waitUploadingAttachment: (state) => {
    return Promise.all(state.uploadingAttachments.map((p) => p.catch(() => {})))
  },
  getBrandAdditionalQuestionsSuccess: (state) => state.brandAdditionalQuestionsSuccess,
  getHotelInfo: (state) => state.hotelInfo,
  getDataLayerPaymentEventPayloadFromBw: (state) => state.dataLayerPaymentEventPayloadFromBw,
  getGtmSessionsFromBw: (state) => state.gtmSessionsFromBw,
  getTrackingInfoFromBw: (state) => state.trackingInfoFromBw,
  storageData: (state, _, rootState) => ({
    bookingDetails: state.bookingDetails,
    guestList: state.guests,
    additionalQuestions: state.additionalQuestions,
    reservationDetails: state.reservationDetails,
    selectedExtras: rootState.extras.selectedExtras,
    signedInToken: rootState.membership.loggedInToken,
    dataLayerPaymentEventPayloadFromBw: state.dataLayerPaymentEventPayloadFromBw,
    gtmSessionsFromBw: state.gtmSessionsFromBw,
    trackingInfoFromBw: state.trackingInfoFromBw,
    facilityNotifications: state.facilityNotifications,
    brandNotifications: state.brandNotifications,
    searchResultPrice: rootState.search.totalPriceFromSearch,
    mcp: {
      selectedCurrency: rootState.mcp.selectedCurrency,
      temporaryCurrency: rootState.mcp.temporaryCurrency
    },
    receipt: rootState.receipt,
    loggedInUser: rootState.membership.loggedInUser
  }),
  getLineLiffToken: (state) => state.lineLiffToken
}

const mutations = {
  setIsBookingFromPayment: (state, isBookingFromPayment) => {
    state.isBookingFromPayment = isBookingFromPayment
  },
  setBookingDetails: (state, payload) => {
    state.bookingDetails = payload
  },
  setBookingDetailsPaymentType: (state, payload) => {
    state.bookingDetails.payment.type = payload
  },
  setReservationDetails: (state, payload) => {
    state.reservationDetails = payload
  },
  setGuest: (state, payload) => {
    state.bookingDetails.guest = payload
  },
  setGuestsInRooms: (state, rooms) => {
    state.bookingDetails.rooms = rooms
  },
  setPerRoomPeopleInRooms: (state, { roomIndex, attr, value }) => {
    const questionIndex = state.additionalQuestions.findIndex(
      (question) => question.question_type === QUESTION_TYPE.PEOPLE_PER_ROOM
    )
    state.additionalQuestions[questionIndex].answer.rooms[roomIndex][attr] = value
  },
  setCheckinTime: (state, { question, value }) => {
    const questionIndex = state.additionalQuestions.findIndex((q) => q.id === question.id)
    state.additionalQuestions[questionIndex].answer.rooms = state.additionalQuestions[
      questionIndex
    ].answer.rooms.map((room) => {
      return {
        ...room,
        checkin_time: value
      }
    })
  },
  setCheckoutTime: (state, { value }) => {
    const questionIndex = state.additionalQuestions.findIndex(
      (question) => question.question_type === QUESTION_TYPE.CHECKOUT
    )
    state.additionalQuestions[questionIndex].answer.rooms = state.additionalQuestions[
      questionIndex
    ].answer.rooms.map((room) => {
      return {
        ...room,
        checkout_time: value
      }
    })
  },
  setQuestionAnswerText: (state, { index, value }) => {
    state.additionalQuestions[index].answer.text = value
  },
  setQuestionAnswer: (state, { index, value }) => {
    state.additionalQuestions[index].answer = value
  },
  setQuestionAttachments: (state, { index, value }) => {
    state.additionalQuestions[index].remote_attachments_urls = value
  },
  setAdditionalQuestions: (state, additionalQuestions) => {
    state.additionalQuestions = [...additionalQuestions]
  },
  setNotifications: (state, notifications) => {
    state.facilityNotifications = notifications
  },
  setGuestsList: (state, payload) => {
    state.guests = payload
  },
  setPoints: (state, payload) => {
    if (payload.selectedSpendingPoint || payload.selectedSpendingPoint === 0)
      state.points.selectedSpendingPoint = payload.selectedSpendingPoint
    if (payload.adjustPoint || payload.adjustPoint === 0)
      state.points.adjustPoint = payload.adjustPoint
  },
  setUpdateSetting: (state, setting) => {
    state.updateSetting = setting
  },
  setUpdateMode: (state, isUpdateMode) => {
    state.isUpdateMode = isUpdateMode
  },
  setApplyingAfterCoupon: (state, isApplyingAfterCoupon) => {
    state.isApplyingAfterCoupon = isApplyingAfterCoupon
  },
  setBrandNotifications: (state, notifications) => {
    state.brandNotifications = notifications
  },
  setCurrentStep: (state, payload) => {
    state.currentStep = payload
  },
  resetAdditionalQuestions: (state) => {
    state.additionalQuestions = []
  },
  setUploadingAttachment: (state, job) => state.uploadingAttachments.push(job),
  setBrandAdditionalQuestionsSuccess: (state, brandAdditionalQuestionsSuccess) => {
    state.brandAdditionalQuestionsSuccess = brandAdditionalQuestionsSuccess
  },
  setHotelInfo: (state, payload) => {
    state.hotelInfo = payload
  },
  setDataLayerPaymentEventPayloadFromBw: (state, payload) => {
    state.dataLayerPaymentEventPayloadFromBw = payload
  },
  setGtmSessionsFromBw: (state, payload) => {
    state.gtmSessionsFromBw = payload
  },
  setTrackingInfoFromBw: (state, payload) => {
    state.trackingInfoFromBw = payload
  },
  setLineLiffToken: (state, lineLiffToken) => {
    state.lineLiffToken = lineLiffToken
  }
}

const actions = {
  setIsBookingFromPayment: ({ commit }, payload) => {
    commit('setIsBookingFromPayment', payload)
  },
  setBookingDetails: ({ commit }, payload) => {
    commit('setBookingDetails', payload)
  },
  setBookingDetailsPaymentType: ({ commit }, payload) => {
    commit('setBookingDetailsPaymentType', payload)
  },
  setReservationDetails: ({ commit, rootGetters }, payload) => {
    if (payload.rooms) {
      payload = {
        ...payload,
        rooms: payload.rooms.map((room) => {
          return {
            ...room,
            special_discount: findDiscountInRoom(room, 'special'),
            rounding_discount: findDiscountInRoom(room, 'rounding')
          }
        })
      }
      payload.total_special_discount = payload.rooms.reduce((a, c) => {
        return a + (c.special_discount?.discount_amount || 0)
      }, 0)
      if (payload.mcp_currency) {
        payload.mcp_total_special_discount = {
          cents: payload.rooms.reduce((a, c) => {
            return (
              a + _get(c.special_discount, formatFieldNameBaseOnMcp('discount_amount', true), 0)
            )
          }, 0),
          currency_iso: payload.mcp_currency
        }
      }
    }
    commit('setReservationDetails', payload)
  },
  fetchBrandNotifications: ({ dispatch, rootGetters, commit }, { pageLabel, isBwReservation }) => {
    const labelFilters = generateBrandLabelFilters(pageLabel, rootGetters)
    return Reservations.getBrandAdditionalQuestions({
      q: JSON.stringify({
        label_filters: labelFilters
      }),
      [isOta && !isBwReservation ? 'tripla_ota_enabled' : 'tripla_book_enabled']: true
    })
      .then((res) => {
        dispatch('setBrandNotifications', res.data)
        commit('setBrandAdditionalQuestionsSuccess', true)
      })
      .catch((e) => {
        console.info(`error ${JSON.stringify(e)}`)
        console.info(`payload ${JSON.stringify(labelFilters)}`)
        // Vue.rollbar.info('Brand additonal questions api failed on guest detail page')
        commit('setBrandAdditionalQuestionsSuccess', false)
      })
  },
  setInitAdditionalQuestions: ({ commit }, additionalQuestions) => {
    const rooms =
      additionalQuestions?.rooms ||
      ((state.bookingDetails?.rooms ?? []).length > 0
        ? state.bookingDetails.rooms
        : state.reservationDetails.rooms) ||
      []
    additionalQuestions = (additionalQuestions?.additional_questions || additionalQuestions).map(
      (question) => {
        if (question.question_type === QUESTION_TYPE.CHECKIN) {
          return {
            ...question,
            answer: {
              rooms: rooms.map((room, roomIndex) => {
                return {
                  reservation_hotel_room_id:
                    room.id || question?.answer?.rooms[roomIndex]?.reservation_hotel_room_id,
                  checkin_time: _get(question, ['answer', 'rooms', 0, 'checkin_time'])
                }
              })
            }
          }
        } else if (question.question_type === QUESTION_TYPE.CHECKOUT) {
          return {
            ...question,
            answer: {
              rooms: rooms.map((room, roomIndex) => {
                return {
                  reservation_hotel_room_id:
                    room.id || question?.answer?.rooms[roomIndex]?.reservation_hotel_room_id,
                  checkout_time: _get(question, ['answer', 'rooms', 0, 'checkout_time'])
                }
              })
            }
          }
        } else if (question.question_type === QUESTION_TYPE.PEOPLE_PER_ROOM) {
          const required = question.required
          return {
            ...question,
            answer: {
              rooms: rooms.map((room, roomIndex) => {
                return {
                  adults:
                    Number(room.adults) ||
                    room.men_count + room.women_count ||
                    question?.answer?.rooms[roomIndex]?.adults,
                  reservation_hotel_room_id:
                    room.id || question?.answer?.rooms[roomIndex]?.reservation_hotel_room_id,
                  men_count: _get(
                    question,
                    ['answer', 'rooms', roomIndex, 'men_count'],
                    required ? room.adults : null
                  ),
                  women_count: _get(
                    question,
                    ['answer', 'rooms', roomIndex, 'women_count'],
                    required ? 0 : null
                  )
                }
              })
            }
          }
        } else if (
          question.question_type === QUESTION_TYPE.FREE &&
          question.answer_type === ANSWER_TYPE.TEXT
        ) {
          return {
            ...question,
            answer: {
              text: null,
              required: question.required,
              ...question.answer
            }
          }
        } else if (
          question.question_type === QUESTION_TYPE.FREE &&
          question.answer_type === ANSWER_TYPE.OPTIONS
        ) {
          return {
            ...question,
            answer: {
              text: null,
              required: question.required,
              ...question.answer
            }
          }
        }
        return question
      }
    )

    commit('setAdditionalQuestions', additionalQuestions)
  },
  resetAdditionalQuestions({ commit }) {
    commit('resetAdditionalQuestions')
  },
  setGuest: ({ commit }, payload) => {
    commit('setGuest', payload)
  },
  setGuestsInRooms: ({ commit, getters }, guests) => {
    let rooms = getters.getBookingDetails.rooms
    rooms = rooms.map((room, index) => {
      return {
        ...room,
        guest: {
          first_name: guests[index].first_name,
          last_name: guests[index].last_name,
          first_name_kana: guests[index].first_name_kana,
          last_name_kana: guests[index].last_name_kana
        }
      }
    })
    commit('setGuestsInRooms', rooms)
    commit('setGuestsList', guests)
  },
  setPerRoomPeopleInRooms: ({ commit, state }, payload) => {
    commit('setPerRoomPeopleInRooms', payload)
    const otherAttr = payload.attr === 'men_count' ? 'women_count' : 'men_count'
    const rooms =
      (state.bookingDetails?.rooms ?? []).length > 0
        ? state.bookingDetails.rooms
        : state.reservationDetails.rooms
    commit('setPerRoomPeopleInRooms', {
      roomIndex: payload.roomIndex,
      attr: otherAttr,
      value: _isFinite(payload.value)
        ? (rooms[payload.roomIndex]?.adults || payload.value) - payload.value
        : null
    })
  },
  setCheckinTime: ({ commit }, payload) => {
    commit('setCheckinTime', payload)
  },
  setCheckoutTime: ({ commit }, payload) => {
    commit('setCheckoutTime', payload)
  },
  resetPoints({ commit }) {
    commit('setPoints', { selectedSpendingPoint: 0, adjustPoint: 0 })
  },
  setUpdateSetting: ({ commit }, payload) => {
    commit('setUpdateSetting', payload)
  },
  setUpdateMode: ({ commit }, isUpdateMode) => {
    commit('setUpdateMode', isUpdateMode)
    if (!isUpdateMode) {
      commit('setApplyingAfterCoupon', false)
    }
  },
  setApplyingAfterCoupon: ({ commit }, isApplyingAfterCoupon) => {
    commit('setApplyingAfterCoupon', isApplyingAfterCoupon)
  },
  setFacilityNotifications: (state, notifications) => {
    state.facilityNotifications = notifications
  },
  setBrandNotifications: ({ commit, rootState }, questions) => {
    commit(
      'setBrandNotifications',
      questions.filter(
        (question) =>
          question.question_type === NOTIFICATION &&
          (question.labels.includes('target_all_facilities') ||
            question.labels.includes(`target_facility_${rootState.setting.init?.hotel_id}`))
      )
    )
  },
  getBookingPrice: ({ commit, dispatch, getters }, { searchParams }) => {
    return Rooms.getSelectedData(searchParams).then((res) => {
      commit('setBookingDetails', {
        guest: getters.getGuest || {},
        ...res
      })
      dispatch('error/clearReservationError', null, { root: true })
      return res
    })
  },
  setCurrentStep: ({ commit }, step) => {
    commit('setCurrentStep', step)
  },
  setHotelInfo: ({ commit }, payload) => {
    commit('setHotelInfo', payload)
  },
  setDataLayerPaymentEventPayloadFromBw: ({ commit }, payload) => {
    commit('setDataLayerPaymentEventPayloadFromBw', payload)
  },
  setGtmSessionsFromBw: ({ commit }, payload) => {
    commit('setGtmSessionsFromBw', payload)
  },
  setTrackingInfoFromBw: ({ commit }, payload) => {
    commit('setTrackingInfoFromBw', payload)
  },
  setLineLiffToken: ({ commit }, lineLiffToken) => {
    commit('setLineLiffToken', lineLiffToken)
  },
  restoreStorageData: async ({ dispatch, commit }, payload) => {
    const {
      signedInToken,
      bookingDetails,
      guestList,
      additionalQuestions,
      reservationDetails = {},
      selectedExtras,
      dataLayerPaymentEventPayloadFromBw,
      gtmSessionsFromBw,
      trackingInfoFromBw,
      facilityNotifications,
      brandNotifications,
      searchResultPrice,
      mcp,
      receipt,
      loggedInUser
    } = payload
    dispatch('setBookingDetails', bookingDetails)
    dispatch('setGuestsInRooms', guestList)
    commit('setAdditionalQuestions', additionalQuestions)
    commit('setNotifications', facilityNotifications)
    commit('setBrandNotifications', brandNotifications)
    dispatch('setReservationDetails', reservationDetails)
    dispatch('setDataLayerPaymentEventPayloadFromBw', dataLayerPaymentEventPayloadFromBw)
    dispatch('setGtmSessionsFromBw', gtmSessionsFromBw)
    dispatch('setTrackingInfoFromBw', trackingInfoFromBw)
    dispatch('extras/overrideSelectedExtraItems', selectedExtras, { root: true })
    dispatch('extras/setExtraItems', bookingDetails?.extras ?? [], { root: true })
    dispatch('membership/setLoggedInToken', signedInToken, { root: true })
    dispatch('search/setTotalPriceFromSearch', searchResultPrice, { root: true })
    dispatch(
      'search/setSearchForm',
      {
        checkin: bookingDetails.checkin_date,
        checkout: bookingDetails.checkout_date
      },
      { root: true }
    )

    dispatch('membership/setLoggedInUser', loggedInUser, { root: true })
    const { selectedCurrency, temporaryCurrency } = mcp
    dispatch('mcp/setSelectedCurrency', selectedCurrency, { root: true })
    dispatch('mcp/setTemporaryCurrency', temporaryCurrency, { root: true })

    // receipt
    dispatch('receipt/setReceiptInfo', receipt, { root: true })
  }
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
}
